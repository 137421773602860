import React from 'react';
import styled from 'styled-components';
import { HCHeadingThree, HCLabelOne } from 'src/components/shared/HypercareComponents';

interface Props {
  title: string;
  description: React.ReactNode;
}

const BannerContainer = styled.div`
  background-color: yellow;
  padding: 20px;
  text-align: center;
  border: 2px solid red;
`;

const LoginDisabledBanner = ({ title, description }: Props) => {
  return (
    <BannerContainer>
      <HCHeadingThree>{title}</HCHeadingThree>
      <HCLabelOne color="red">{description}</HCLabelOne>
    </BannerContainer>
  );
};

export default LoginDisabledBanner;
