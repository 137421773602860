import React from 'react';
import { EXISTING, NEW } from 'src/constants/inviteUserTypes';
interface Props {
  setFormType: (newType: 'existing' | 'new') => void;
  currentFormType: 'existing' | 'new';
}

const InviteUserSelection = ({ setFormType, currentFormType }: Props) => {
  return (
    <div className="modal__formSelectors">
      <div
        onClick={() => setFormType(EXISTING)}
        className={`modal__formSelector__wrapper--default ${
          currentFormType === EXISTING ? 'modal__formSelector__wrapper--selected' : ''
        }`}
      >
        <span>Invite by email</span>
      </div>
      <div
        onClick={() => setFormType(NEW)}
        className={`modal__formSelector__wrapper--default ${
          currentFormType === NEW ? 'modal__formSelector__wrapper--selected' : ''
        }`}
      >
        <span>Create a New User</span>
      </div>
    </div>
  );
};

export default InviteUserSelection;
